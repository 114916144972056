@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-neutral-900;
}

.container {
  @apply max-w-screen-lg mx-auto px-5;
}
